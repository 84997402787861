/*
    yell apiから取得したjsonをdecode
*/
export function decodeJsonForYellApi(data) {
    if (typeof data === 'undefined') {
        return [];
    };
    return JSON.parse(
        decodeURIComponent(
            escape(
                atob(
                    data
                )
            )
        )
    );
}