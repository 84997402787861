import React from 'react';
import './ContestStatus.scss';

class ContestStatus extends React.Component {

    handleContestState(contestStatus) {
        switch (contestStatus) {
            case 1:
                return <div className="before-vote">近日公開</div>;
            case 2:
                return <div className="voting">受付中</div>;
            case 3:
                return <div className="aggregate-vote">集計中</div>;
            case 4:
                return <div className="end-vote">投票終了</div>;
            default:
                return <></>;
        }
    }

    render() {
        return (
            <div id="contest-status">
                {this.handleContestState(
                    this.props.data.contest_state,
                )}
            </div>
        );
    }
}

export default ContestStatus;