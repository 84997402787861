import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import ContestStatus from '../common/ContestStatus';
import LazyLoad from '../common/LazyLoad';
import './ContestColumn.scss';

class ContestColumn extends React.Component {

    render() {
        const contestImage = (
            <div className="contest-image">
                <LazyLoad>
                    <img src={this.props.col.header_image} alt="header" />
                </LazyLoad>
            </div>
        );
        
        const contestStatus = (
            <div className="contest-status">
                <ContestStatus data={this.props.col} />
            </div>
        );

        const collegeName = (
            <div className="college-name">
                {this.props.col.college_name}
            </div>
        );

        const contestName = (
            <div className="contest-name">
                {this.props.col.contest_name}
            </div>
        );

        const contestPeriod = (
            <div className="contest-period">
                投票期間：
                <Moment>
                    {this.props.col.vote_start_time}
                </Moment>
                〜
                <Moment>
                    {this.props.col.vote_end_time}
                </Moment>
            </div>
        );

        return (
            <div id="contest-column">
                <Link to={`/contests/${this.props.col.contest_id}/candidates`}>
                    {contestImage}
                    <div className="top">
                        {contestStatus}
                        {collegeName}
                    </div>
                    {contestName}
                    {contestPeriod}
                </Link>
            </div>
        );
    }
}

export default ContestColumn;