import React from 'react';
import { Button } from 'react-bootstrap';

import Header from '../common/Header';
import Footer from '../common/Footer';
import SubFooter from '../common/SubFooter';
import MediaQuery from 'react-responsive';
import { instanceOf } from 'prop-types';
import { decodeJsonForYellApi } from '../common/Utils';
import { OwnerGroupInfoBreadcrumbs } from '../common/Breadcrumbs';
import { withCookies, Cookies} from 'react-cookie';
import './OwnerGroupInfo.scss';

class OwnerGroupInfo extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = props;
        const _user_service = decodeJsonForYellApi(cookies.get('_user_service'));
        this.state = {
            _user_service: _user_service,
        }
    }

    render() {
        const breadcrumbs = (
            <div id="breadcrumbs">
                <div className="breadcrumbs-item">
                    <OwnerGroupInfoBreadcrumbs />
                </div>
            </div>
        );

        // mail設定
        const mailTo = 'mailto:mixch-support@donuts.ne.jp';
        const mailSubject = '?subject=MixChannelYell%40%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B';
        const mailBody = '&body=%E3%83%BB%E5%9B%A3%E4%BD%93%E5%90%8D%EF%BC%88%E6%B3%95%E4%BA%BA%E5%90%8D%EF%BC%89%EF%BC%9A%0D%0A%E3%83%BB%E6%B0%8F%E5%90%8D%EF%BC%9A%0D%0A%E3%83%BB%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%EF%BC%9A';
        const mailTemplate = mailTo + mailSubject + mailBody;

        const toOwnerGroupTitle = (
            <h1>お問い合わせ(運営団体の方)</h1>
        );
        
        const toOwnerGroupBody = (
            <>
                <h2><label className="list-mark">　</label>　運営団体、大学の方へ</h2>
                <p>
                    MixChannelでは、コンテスト情報を掲載したい運営団体、大学を募集しております。<br />
                    本サイトでのプロフィール掲載や投票機能に加えて、MixChannelにて配信イベントを実施することによりコンテストの盛り上がり最大化に貢献いたします。<br />
                </p>
            </>
        );
        const toOwnerGroupImages = (
            <>
                <div className="to-owner">
                    <img src="/to-owner1.jpg" alt="to-owner" />
                    <img src="/to-owner2.jpg" alt="to-owner" />
                </div>
                {/* <p className="annotation">
                    ※注釈
                </p> */}
            </>
        );

        const achievementsTitle = (
            <>
                <h2><label className="list-mark">　</label>　導入実績</h2>
            </>
        );
        const achievementsImages = (
            <div className="introduction-record">
                <img src="/recruit_banner.png" alt="introduction-record" />
            </div>
        );

        const contactusTitle = (
            <h3>団体名（法人名）、氏名は必ずご入力ください。</h3>
        );

        const contactusButton = (
            <div className="contactus-btn">
                <a href={mailTemplate} target="_blank" rel="noopener noreferrer">
                    <Button variant="light">
                        <span>
                            <img src="/mail_icon.png" alt="mail-icon"></img>
                            お問い合わせはこちら
                        </span>
                    </Button>
                </a>
            </div>
        );

        return (
            <div id="owner-group-info" className="content-area">
                <Header user={this.state._user_service} />
                <MediaQuery query="(min-width: 768px)">
                    {breadcrumbs}
                </MediaQuery>
                <div className="owner-group-info">
                    {toOwnerGroupTitle}
                    {toOwnerGroupBody}
                    {toOwnerGroupImages}
                    {achievementsTitle}
                    {achievementsImages}
                    <div className="contactus">
                        {contactusTitle}
                        {contactusButton}
                    </div>
                    <MediaQuery query="(max-width: 767px)">
                        {breadcrumbs}
                    </MediaQuery>
                </div>
                <Footer />
                <SubFooter _user_service={this.state._user_service} />
            </div>
        )
    }
}

export default withCookies(OwnerGroupInfo);