import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import ContestStatus from '../common/ContestStatus';
import './PickupContest.scss';

class PickupContest extends React.Component {

    // 投票受付中のコンテストを降順でソート
    sortContestsByContestSatate(contests, contestState) {
        const votingContests = contests.filter( (contest) => contest.contest_state === contestState);
        return votingContests.sort((a, b) => {
            if (a.vote_start_time > b.vote_start_time) return -1;
            if (a.vote_start_time < b.vote_start_time) return 1;
            return 0
        });
    }

    render() {
        const contests = this.props.contests;
        const descBeforeVote = this.sortContestsByContestSatate(contests, 1);
        const descVotingContests = this.sortContestsByContestSatate(contests, 2);
        const descAggregateVoteContests = this.sortContestsByContestSatate(contests, 3);
        const descEndVoteContests = this.sortContestsByContestSatate(contests, 4);
        const pickupContest = descVotingContests[0] || descBeforeVote[0] || descAggregateVoteContests[0] || descEndVoteContests[0];
        if (!pickupContest) {
            return <></>;
        }
        const pickupContestImg = pickupContest.header_image || '';
        const backgroundUrl = { background: `url(${pickupContestImg})`};

        const contestImage = (
            <img src={pickupContestImg} alt="contest" />
        );

        const collegeName = (
            <div className="college-name">
                {pickupContest.college_name}
            </div>
        );

        const contestName= (
            <div className="contest-name">
                {pickupContest.contest_name}
            </div>
        )

        const contestStatus = (
            <ContestStatus data={pickupContest} />
        );

        const contestPeriod = (
            <div className="contest-period">
                投票期間：
                <Moment>
                    {pickupContest.vote_start_time}
                </Moment>
                〜
                <Moment>
                    {pickupContest.vote_end_time}
                </Moment>
            </div>
        );

        const detailsBtn = (
            <div className="detail-btn">
                <Link to={`/contests/${pickupContest.contest_id}/candidates`}>
                    <button className="abled">
                        <span>詳細はこちら</span>
                    </button>
                </Link>
            </div>
        );

        return (
            <div fluid id="contests-headline" style={ backgroundUrl }>
                <div className="pickup-contest">
                    <div className="left">
                        {contestImage}
                    </div>
                    <div className="right">
                        {contestStatus}
                        {collegeName}
                        {contestName}
                        {contestPeriod}
                        {detailsBtn}
                    </div>
                </div>
            </div>
        );
    }
}

export default PickupContest;