import React from 'react';

export function CandidateRankingImage(contestStatus, candidateVoteAggregate) {
    if (typeof contestStatus === "undefined" ||
        typeof candidateVoteAggregate === "undefined") {
        return <></>;
    }

    if (contestStatus.contest_state === 4 && candidateVoteAggregate.vote_ranking > 0 && candidateVoteAggregate.vote_ranking <= 3) {
        return (
            <img
                src={`/candidate_ranking/crown-rank-${candidateVoteAggregate.vote_ranking}.png`}
                alt='candidate-ranking-img'
            >
            </img>
        );
    }
    return <></>;
}