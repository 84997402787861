import React from 'react';
import Moment from 'react-moment';
import { withCookies, Cookies} from 'react-cookie';

import CandidateColumn from './CandidateColumn';
import CandidatesModal from '../common/CandidatesModal';
import Footer from '../common/Footer';
import Header from '../common/Header';
import MediaQuery from 'react-responsive';
import SubFooter from '../common/SubFooter';
import SubHeader from '../common/SubHeader';
import ToOwnerGroupInfoButton from '../common/ToOwnerGroupInfoButton';

import { candidateVote } from '../common/CandidateVoteApi';
import { CandidatesBreadcrumbs } from '../common/Breadcrumbs';
import { decodeJsonForYellApi } from '../common/Utils';
import { getCandidatesData } from './CandidatesApi';
import { instanceOf } from 'prop-types';

import '../common/Base.scss';
import './Candidates.scss';

class Candidates extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = props;
        const _user_service = decodeJsonForYellApi(cookies.get('_user_service'))
        const _oauth_callback_info = decodeJsonForYellApi(cookies.get('_oauth_callback_info'))
        this.handleCandidateVote = candidateVote.bind(this);
        this.handleContestVoteLimitUpdate = this.handleContestVoteLimitUpdate.bind(this);
        this.handleCandidateVoteStatusUpdate = this.handleCandidateVoteStatusUpdate.bind(this);
        this.handleCandidateVoteModalStatusUpdate = this.handleCandidateVoteModalStatusUpdate.bind(this);
        this.state = {
            contest_status: [],
            candidate_list: [],
            contest_info: [],
            contest_vote_limit: [],
            candidate_vote_aggregates: [],
            candidate_vote_status: [],
            candidate_vote_modal_is_open: false,
            _user_service: _user_service,
            _oauth_callback_info: _oauth_callback_info,
        };
    }

    // コンテスト投稿上限数更新
    handleContestVoteLimitUpdate(data) {
        this.setState({
            contest_vote_limit: data
        });
    }

    // 投稿処理ステータスを更新
    handleCandidateVoteStatusUpdate(data) {
        this.setState({
            candidate_vote_status: data
        });
    }

    // 投稿処理後モーダルのステータス更新
    handleCandidateVoteModalStatusUpdate(isOpen) {
        this.setState({
            candidate_vote_modal_is_open: isOpen,
        })
    }

    render() {

        const breadcrumbs = (
            <div id="breadcrumbs">
                <div className="breadcrumbs-item">
                    <CandidatesBreadcrumbs
                        match={this.props.match}
                        contest_info={this.state.contest_info}
                    />
                </div>
            </div>
        );

        const contestImage = (
            <div className="header-image">
                <img
                    src={this.state.contest_info.header_image}
                    alt="header"
                />
            </div>
        )

        const subHeader = (
            <SubHeader
                contest_info={this.state.contest_info}
                contest_vote_limit={this.state.contest_vote_limit}
                contest_status={this.state.contest_status}
                _user_service={this.state._user_service}
            />
        );

        const candidatesLabel = (
            <div className="candidates-title">参加者一覧</div>
        );

        const contestInfoTitle = (
            <div className="title">コンテスト概要</div>
        );

        const contestInfoItemArr = [
            {
                name: "大学名",
                val: this.state.contest_info.college_name,
            },
            {
                name: "コンテスト名",
                val: this.state.contest_info.contest_name,
            },
            {
                name: "メインスポンサー",
                val: this.state.contest_info.sponsor,
            },
            {
                name: "主催団体名",
                val: this.state.contest_info.owner_group_name,
            },
            {
                name: "開催日",
                val: this.state.contest_info.final_time && <Moment>{this.state.contest_info.final_time}</Moment>,
            },
            {
                name: "開催場所",
                val: this.state.contest_info.final_place,
            },
            {
                name: "投票開始日",
                val: <Moment format="llll">{this.state.contest_info.vote_start_time}</Moment>,
            },
            {
                name: "投票終了日",
                val: <Moment format="llll">{this.state.contest_info.vote_end_time}</Moment>,
            },
            {
                name: "Twitter",
                val: <a
                    href={`https://twitter.com/${this.state.contest_info.twitter_uniqueid}`}
                    target="_blank" rel="noopener noreferrer"
                >
                    {this.state.contest_info.twitter_uniqueid && `@${this.state.contest_info.twitter_uniqueid}`}
                </a>,
            },
            {
                name: "instagram",
                val: <a 
                    href={`https://www.instagram.com/${this.state.contest_info.instagram_uniqueid}`}
                    target="_blank" rel="noopener noreferrer"
                >
                    <span>{this.state.contest_info.instagram_uniqueid && `@${this.state.contest_info.instagram_uniqueid}`}</span>
                </a>,
            },
        ]

        const contestInfoItems = (
            <div className="contest-info-items">
                {contestInfoItemArr.map( (item, i) => (
                    <div className="item" key={i}>
                        <div className="name">
                            <span>{item.name}</span>
                        </div>
                        <div className="val">
                            <span>{item.val}</span>
                        </div>
                    </div>
                ))}
            </div>
        );

        const contestInfo = (
            <div className="contest-info">
                {contestInfoTitle}
                {contestInfoItems}
            </div>
        );

        const candidates = (
            <div className="candidates">
                {this.state.candidate_list.map( (candidate) => (
                    <CandidateColumn
                        key={candidate.candidate_id}
                        col={candidate}
                        candidate_vote_aggregate={this.state.candidate_vote_aggregates[candidate.candidate_id]}
                        contest_status={this.state.contest_status}
                        contest_vote_limit={this.state.contest_vote_limit}
                        onCandidateVote={this.handleCandidateVote}
                    />
                ))}
            </div>
        );

        return (
            <div id="candidates" className="content-area">
                <Header user={this.state._user_service} />
                <MediaQuery query="(min-width: 768px)">
                    {breadcrumbs}
                </MediaQuery>
                {contestImage}
                {subHeader}
                {candidatesLabel}
                {candidates}
                {contestInfo}
                <MediaQuery query="(max-width: 767px)">
                    {breadcrumbs}
                    <ToOwnerGroupInfoButton />
                </MediaQuery>
                <Footer />
                <SubFooter _user_service={this.state._user_service} />
                <CandidatesModal
                    contest_vote_limit={this.state.contest_vote_limit}
                    candidate_vote_status={this.state.candidate_vote_status}
                    candidate_vote_modal_is_open={this.state.candidate_vote_modal_is_open}
                    _oauth_callback_info={this.state._oauth_callback_info}
                    handleCandidateVoteModalStatusUpdate={this.handleCandidateVoteModalStatusUpdate}
                    onCandidateVote={this.handleCandidateVote}
                />
            </div>
        );
    }

    componentDidMount() {
        const contestId = this.props.match.params.contest_id;
        const oauthSuccess = this.state._oauth_callback_info.oauth_success;
        const shouldVoteContestId = this.state._oauth_callback_info.should_vote_contest_id;
        const shouldVoteCandidateId = this.state._oauth_callback_info.should_vote_candidate_id;
        
        // ログイン成功して遷移した場合、投稿処理
        if (oauthSuccess) {
            this.handleCandidateVote(
                shouldVoteContestId,
                shouldVoteCandidateId,
            );
        }

        // 候補者一覧取得
        getCandidatesData(contestId)
        .then(
            (responseJson) => {
                this.setState({
                    contest_status: decodeJsonForYellApi(responseJson[0]),
                    candidate_list: decodeJsonForYellApi(responseJson[1]),
                    contest_info: decodeJsonForYellApi(responseJson[2]),
                    contest_vote_limit: decodeJsonForYellApi(responseJson[3]),
                    candidate_vote_aggregates: decodeJsonForYellApi(responseJson[4]),
                });
            },
            (error) => {
                console.log(error);
            }
        );
    }
}

export default withCookies(Candidates);