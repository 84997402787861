import React from 'react';
import { Link } from 'react-router-dom';
import Copyright from '../common/Copyright';
import './SubFooter.scss';

class SubFooter extends React.Component {

    render() {
        // mail設定
        const userId = typeof this.props._user_service.user_id === 'undefined'?0:this.props._user_service.user_id;
        const mailTo = 'mailto:mixch-support@donuts.ne.jp';
        const mailSubject = '?subject=MixChannelYell%40%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B';
        const mailBody = '&body=%E3%83%BB%E6%B0%8F%E5%90%8D%EF%BC%9A%0D%0A%E3%83%BB%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%EF%BC%9A';
        const collectUserId = `%0D%0A%0D%0A%E2%80%BB%E4%B8%8D%E5%85%B7%E5%90%88%E6%99%82%E3%81%AE%E8%AA%BF%E6%9F%BB%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AB%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BCID%E3%82%92%E5%88%A9%E7%94%A8%E3%81%97%E3%81%BE%E3%81%99%0D%0A${userId}`;
        let mailTemplate = mailTo + mailSubject + mailBody;
        if (userId > 0) {
            mailTemplate +=  collectUserId;
        }

        return (
            <div id="sub-footer">
                <div className="left">
                    <div className="info">
                        <a href={mailTemplate}  target="_blank" rel="noopener noreferrer">お問い合わせ</a>
                    </div>
                    <span className="separator">|</span>
                    <div className="company-info">
                        <a href="https://www.donuts.ne.jp/about/info.html" target="_blank" rel="noopener noreferrer">会社概要</a>
                    </div>
                    <span className="separator">|</span>
                    <div className="privacy-policy">
                        <Link to="/privacy-policy">プライバシーポリシー</Link>
                    </div>
                </div>
                <div className="right">
                    <Copyright />
                </div>
            </div>
        );
    }
}

export default SubFooter;