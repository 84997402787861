import React from 'react';
import './ContestVoteLimit.scss';

class ContestVoteLimit extends React.Component {

    culcVotelimitDate(voteLimitDate, voteCntDate, contestState) {
        if (contestState !== 2) {
            return ''
        }
        let voteCap = 0;
        if (typeof voteLimitDate !== 'undefined' &&
            typeof voteCntDate !== 'undefined' &&
            voteLimitDate >= voteCntDate) {
            voteCap = voteLimitDate - voteCntDate
        }
        return <>
            <span className="left">本日の応援できる数　</span>
            <span className="right">あと
                <span className="num">
                    {voteCap}
                </span>
                回
            </span>
        </>;
    }

    render() {
        return (
             <div id="contest-vote-limit">
                {this.culcVotelimitDate(
                    this.props.contest_vote_limit.vote_limit_date,
                    this.props.contest_vote_limit.vote_cnt,
                    this.props.contest_status.contest_state,
                )}
            </div>
        )
    }
}

export default ContestVoteLimit;