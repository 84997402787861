import React from 'react';
import './Copyright.scss'

class Copyright extends React.Component {
    render() {
        return (
            <div id="copyright">
                <img
                    src="/mixchyell-logo.png"
                    alt=""
                    className="site-logo"
                />
                <p>© MixChannel YELL ミックスチャンネルエール</p>
            </div>
        );
    }
}

export default Copyright;