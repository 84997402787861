import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import './Breadcrumbs.scss';

const BreadcrumbsConf = () => {
  return (
    <Breadcrumbs
      separator={<span> > </span>}
      item={NavLink}
      finalItem={'span'}
    />
  )
}

const ContestsBreadcrumbsItem = () => {
  return <BreadcrumbsItem to='/'>TOP</BreadcrumbsItem>
}

const CandidatesBreadcrumbsItem = (props) => {
  return (
    <BreadcrumbsItem
      to={`/contests/${props.match.params.contest_id}/candidates`}
    >
      {props.contest_info.contest_name} 参加者一覧
    </BreadcrumbsItem>
  )
}

const CandidateProfileBreadcrumbsItem = (props) => {
  return (
    <BreadcrumbsItem to={`/contests/${props.match.params.contest_id}/candidates/${props.match.params.candidate_id}`}>
      {props.candidate_info.candidate_name}
    </BreadcrumbsItem>
  )
}

const OwnerGroupInfoBreadcrumbsItem = () => {
  return <BreadcrumbsItem to='/owner-group-info'>お問い合わせ（運営団体の方）</BreadcrumbsItem>
}

const PrivacyPolicyBreadcrumbsItem = () => {
  return <BreadcrumbsItem to='/owner-group-info'>プライバシーポリシー</BreadcrumbsItem>
}

export const CandidatesBreadcrumbs = (props) => {
  return (
    <>
      <BreadcrumbsConf />
      <ContestsBreadcrumbsItem />
      <CandidatesBreadcrumbsItem
        match={props.match}
        contest_info={props.contest_info}
      />
    </>
  )
}

export const CandidateProfileBreadcrumbs = (props) => {
  return (
    <>
      <BreadcrumbsConf />
      <ContestsBreadcrumbsItem />
      <CandidatesBreadcrumbsItem
        match={props.match}
        contest_info={props.contest_info} />
      <CandidateProfileBreadcrumbsItem
        match={props.match}
        contest_info={props.contest_info}
        candidate_info={props.candidate_info} />
    </>
  )
}

export const OwnerGroupInfoBreadcrumbs = () => {
  return (
    <>
      <BreadcrumbsConf />
      <ContestsBreadcrumbsItem />
      <OwnerGroupInfoBreadcrumbsItem />
    </>
  )
}

export const PrivacyPolicyBreadcrumbs = () => {
  return (
    <>
      <BreadcrumbsConf />
      <ContestsBreadcrumbsItem />
      <PrivacyPolicyBreadcrumbsItem />
    </>
  )
}
