import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ToOwnerGroupInfoButton from '../common/ToOwnerGroupInfoButton';
import MediaQuery from "react-responsive";
import './Header.scss';

class Header extends React.Component {

    render() {
        const apiBaseUrl = process.env.REACT_APP_API_URL;
        const apiVersion = '/api/v1';
        const logoutUrl = `${apiBaseUrl}${apiVersion}/logout`;
        const siteImage = (
            <div className="site-image">
                <Link to="/" >
                    <img
                        src="/mixchyell-logo.png"
                        alt=""
                        className="site-logo"
                    />
                </Link>
            </div>
        );

        const logout = (
            <div className="logout">
                {
                    this.props.user.login_status &&
                    <a href={logoutUrl}>
                        <MediaQuery query="(max-width: 767px)">
                            <Image src="/ico-logout.png"></Image>
                        </MediaQuery>
                        <MediaQuery query="(min-width: 768px)">
                            <button>
                                <span>ログアウト</span>
                            </button>
                        </MediaQuery>
                    </a>
                }
            </div>
        );

        return (
            <div id="header">
                {siteImage}
                <div className="right">
                    <MediaQuery query="(min-width: 768px)">
                        <ToOwnerGroupInfoButton />
                    </MediaQuery>
                    {logout}
                </div>
            </div>
        );
    }

}

export default Header;