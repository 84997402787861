
export async function getCandidateProfile(contestId, candidateId) {
    const apiVersion = '/api/v1';
    const resourceContest = `${apiVersion}/contests/${contestId}`;
    const resourceContestStatus = `${resourceContest}/status`;
    const resourceCandidate = `${resourceContest}/candidates/${candidateId}`;
    const resourceCandidateImages = `${resourceContest}/candidates/${candidateId}/images`;
    const resourceCandidateInterviews = `${resourceContest}/candidates/${candidateId}/interviews`;
    const resourceContestVoteLimit = `${resourceContest}/vote-limit`;
    const resourceCandidateVoteAggregate = `${resourceContest}/candidates/${candidateId}/vote-aggregate`;
    try {
        const responses = await Promise.all(
            [
                resourceCandidate,
                resourceCandidateImages,
                resourceCandidateInterviews,
                resourceContest,
                resourceContestStatus,
                resourceContestVoteLimit,
                resourceCandidateVoteAggregate,
            ].map(url =>  {
                return fetch(url);
            })
        );
        return await Promise.all(responses.map(r => r.json()));
    }
    catch (error) {
        console.log(error);
    }
}