import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import '../common/ToOwnerGroupInfoButton.scss';

class ToOwnerGroupInfoButton extends React.Component {
    render() {
        return (
            <div id="to-owner-group-info">
                <Link to="/owner-group-info">
                    <button>
                        <div className="mail-icon">
                            <Image src="/mail_icon.png"></Image>
                        </div>
                        <div className="text">
                            <p>運営団体、大学の方へ<br /><span>お問い合わせはこちら</span></p>
                        </div>
                        <div className="greater-than">
                            <Image src="/arrow-sp-menu.png" />
                        </div>
                    </button>
                </Link>
            </div>
        );
    }
}

export default ToOwnerGroupInfoButton;