import React from 'react';
import { Link } from 'react-router-dom';

import { CandidateRankingImage } from '../common/CandidateRankingImage';
import CandidateVote from '../common/CandidateVote';
import LazyLoad from '../common/LazyLoad';
import './CandidateColumn.scss';

class CandidateColumn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hoverCandidateProfileImage: false,
        }
        this.handleCandidateVote = this.handleCandidateVote.bind(this);
        this.onMouseEnterProfileImage = this.onMouseEnterProfileImage.bind(this);
        this.onMouseLeaveProfileImage = this.onMouseLeaveProfileImage.bind(this);
    }

    // 投稿処理
    handleCandidateVote(contestId, candidateId) {
        this.props.onCandidateVote(contestId, candidateId)
    }

    onMouseEnterProfileImage() {
        this.setState({
            hoverCandidateProfileImage: true,
        });
    }

    onMouseLeaveProfileImage() {
        this.setState({
            hoverCandidateProfileImage: false,
        });
    }

    render() {
        const candidateProfileLink = `/contests/${this.props.col.contest_id}/candidates/${this.props.col.candidate_id}`;

        const contestId = this.props.col.contest_id
        const candidateId = this.props.col.candidate_id
        const contestVoteLimit = this.props.contest_vote_limit;
        const candidateVoteAggregate = this.props.candidate_vote_aggregate;
        const contestStatus = this.props.contest_status;

        const candidateRankingImage = (
            <div className="candidate-ranking">
                {CandidateRankingImage(contestStatus, candidateVoteAggregate)}
            </div>
        );

        const candidateImage = (
            <div
                className="candidate-image"
                onMouseEnter={this.onMouseEnterProfileImage}
                onMouseLeave={this.onMouseLeaveProfileImage}
            >
                <Link to={candidateProfileLink}>
                    <LazyLoad>
                        <img className={ this.state.hoverCandidateProfileImage ? "hover":""} src={this.props.col.profile_image} />
                    </LazyLoad>
                    {this.state.hoverCandidateProfileImage && <div><span>PROFILE</span></div> }
                </Link>
            </div>
        );

        const candidateName = (
            <div className="candidate-name">
                <p>
                    {this.props.col.candidate_name}
                </p>
            </div>
        );

        const candidateVoteBtn = (
            <CandidateVote
                contest_id={contestId}
                candidate_id={candidateId}
                contest_status={contestStatus}
                candidate_vote_limit={contestVoteLimit}
                onCandidateVote={this.handleCandidateVote}
            />
        );

        return (
            <div id="candidate-column">
                {candidateRankingImage}
                {candidateImage}
                {candidateName}
                {candidateVoteBtn}
            </div>
        );
    }
}

export default CandidateColumn;