import React from 'react';
import { withCookies, Cookies} from 'react-cookie';
import ContestColumn from './ContestColumn';
import Header from '../common/Header';
import MediaQuery from 'react-responsive';
import Footer from '../common/Footer';
import PickupContest from './PickupContest';
import SubFooter from '../common/SubFooter';
import ToOwnerGroupInfoButton from '../common/ToOwnerGroupInfoButton';
import { getContestsData } from './ContestsApi';
import { decodeJsonForYellApi } from '../common/Utils';
import { instanceOf } from 'prop-types';
import '../common/Base.scss';
import '../common/Breadcrumbs.scss';
import './Contests.scss';

class Contests extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };

    constructor(props) {
        super(props);
        const { cookies } = props;
        const _user_service = decodeJsonForYellApi(cookies.get('_user_service'))
        this.state = {
            contest_list: [],
            _user_service: _user_service,
        };
    }

    render() {
        const contests = this.state.contest_list;
        const pickupContest = (
            contests.length !== 0 && <PickupContest contests={contests} />
        );

        const title = (
            <div className="title">
                <h1>
                    コンテスト一覧
                </h1>
            </div>
        );

        const contestList = (
            <div className="contests">
                {contests.map( (contests) => (
                    <ContestColumn col={contests} key={contests.contest_id} />
                ))}
            </div>
        );

        return (
            <div id="contests" className="content-area">
                <Header user={this.state._user_service} />
                {pickupContest}
                {title}
                {contestList}
                <MediaQuery query="(max-width: 767px)">
                    <ToOwnerGroupInfoButton />
                </MediaQuery>
                <Footer />
                <SubFooter _user_service={this.state._user_service} />
            </div>
        );
    }

    componentDidMount() {
        getContestsData()
        .then(
            (data) => {
                this.setState({
                    contest_list: decodeJsonForYellApi(data),
                });
            },
            (error) => {
                console.log(error);
            }
        );
    }
}

export default withCookies(Contests);