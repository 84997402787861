import React from 'react';
import { Carousel, Image} from 'react-bootstrap';
import { Share } from 'react-twitter-widgets';
import MediaQuery from 'react-responsive';
import Moment from 'react-moment';

import { CandidateRankingImage } from '../common/CandidateRankingImage';
import CandidateVote from '../common/CandidateVote';
import CommonModal from '../common/Modal';
import ContestVoteLimit from '../common/ContestVoteLimit';
import './CandidateProfileInfo.scss';

class CandidateProfileInfo extends React.Component {
    constructor(props) {
        super(props);
        this.handleCandidateVote = this.handleCandidateVote.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            imageUrl: '',
            arrows: false,
            coordinateY: 0,
        }
    }

    openModal(url) {
        this.setState({
            imageUrl: url,
        });
        this.props.handleCandidateProfileModalStatusUpdate(true);
    }

    closeModal() {
        this.props.handleCandidateProfileModalStatusUpdate(false);
    }

    handleCandidateVote(contestId, candidateId) {
        this.props.onCandidateVote(contestId, candidateId)
    }

    handleCarouselArrows(candidateImages) {
        return candidateImages.length !== 0 ? true : false;
    }


    handleScroll() {
        const position = window.pageYOffset;
        this.setState({
            coordinateY: position,
        });
    }

    render() {
        const modalIsOpen = this.props.candidate_profile_modal_is_open;
        const contestVoteLimit = this.props.contest_vote_limit;
        const candidateVoteAggregate = this.props.candidate_vote_aggregate;
        const contestStatus = this.props.contest_status;
        const mixchId    = this.props.candidate_info.mixch_uniqueid;
        const twitterId = this.props.candidate_info.twitter_uniqueid;
        const instagramId = this.props.candidate_info.instagram_uniqueid;
        const bodyHeight = document.body.clientHeight;
        const clientScreenHeight = document.documentElement.clientHeight

        const candidateProfileInfoImages = (
            <>
                <MediaQuery query="(max-width:767px)">
                    <div class="col-lg-6 offset-lg-3" id="slider">
                        <div id="carousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="active item carousel-item" data-slide-number="0">
                                    <img
                                        src={this.props.candidate_info.profile_image}
                                        alt="profile"
                                        onClick={() => this.openModal(
                                            this.props.candidate_info.profile_image
                                        )}
                                    />
                                </div>
                                {this.props.candidate_images.map( (image, i) => (
                                    <div class="item carousel-item" data-slide-number={i + 1} key={image.candidate_image_id}>
                                        <img
                                            src={image.image_url}
                                            alt="profiles"
                                            onClick={() => this.openModal(image.image_url)}
                                        />
                                    </div>
                                ))}
                            </div>

                            <ul class="carousel-indicators list-inline">
                                <li class="list-inline-item active">
                                    <a id="carousel-selector-0" class="selected" data-slide-to="0" data-target="#carousel">
                                        <img
                                            src={this.props.candidate_info.profile_image}
                                            alt="profile"
                                        />
                                    </a>
                                </li>
                                {this.props.candidate_images.map( (image, i) => (
                                    <li class="list-inline-item">
                                        <a id={`carousel-selector-${i + 1}`} data-slide-to={i + 1} data-target="#carousel">
                                            <img src={image.image_url} alt="candidate" />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width:768px)">
                    <Carousel
                        nextIcon={<img src="/candidate_profile/ico-arrow-r.png" alt="arrow-r" aria-hidden="true" className="next-icon" />}
                        prevIcon={<img src="/candidate_profile/ico-arrow-l.png" alt="arrow-l" aria-hidden="true" className="prev-icon" />}
                        className="carousel-thumbnails"
                        controls={this.handleCarouselArrows(this.props.candidate_images)}
                    >
                        <Carousel.Item>
                            <img
                                src={this.props.candidate_info.profile_image}
                                alt="profile"
                                onClick={() => this.openModal(
                                    this.props.candidate_info.profile_image
                                )}
                            />
                        </Carousel.Item>
                        {this.props.candidate_images.map( (image, i) => (
                            <Carousel.Item key={image.candidate_image_id}>
                                <img
                                    src={image.image_url}
                                    alt="profiles"
                                    onClick={() => this.openModal(image.image_url)}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </MediaQuery>
            </>
        );

        const entryNumber = (
            <div className="entry-number">
                <span>エントリーNo.{this.props.candidate_info.entry_number}</span>
            </div>
        );

        const candidateName = (
            <div className="candidate-name">
                <p className="name">
                    <span className="candidate-ranking">
                        {CandidateRankingImage(contestStatus, candidateVoteAggregate)}
                    </span>
                    {this.props.candidate_info.candidate_name}
                </p>
                <p className="name-romaji">
                    {this.props.candidate_info.candidate_name_romaji}
                </p>
            </div>
        );

        const contestName = (
            <p className="contest-name">
                {this.props.contest_info.contest_name}
            </p>
        );

        const votePeriod = (
            <p className="vote-period">
                投票期間：
                <Moment>
                    {this.props.contest_info.vote_start_time}
                </Moment>
                〜
                <Moment>
                    {this.props.contest_info.vote_end_time}
                </Moment>
            </p>
        );

        const btnSnsMixch = (
            <div className="btn-sns">
                <a
                    className="btn-mixch-image"
                    href={`https://mixch.tv/u/${mixchId}`}
                    target="_blank" rel="noopener noreferrer"
                >
                    <Image
                        src="/sns-btn/btn-mixch.png"
                        alt="mixch"
                    />
                </a>
            </div>
        );
        const btnSnsTwitter = (
            <div className="btn-sns">
                <a
                    className="btn-tw-image"
                    href={`https://twitter.com/${twitterId}`}
                    target="_blank" rel="noopener noreferrer"
                >
                    <Image
                        src="/sns-btn/btn-tw.png"
                        alt="twitter"
                    />
                </a>
            </div>
        );
        const btnSnsInstagram = (
            <div className="btn-sns">
                <a
                    className="btn-insta-image"
                    href={`https://www.instagram.com/${instagramId}`}
                    target="_blank" rel="noopener noreferrer"
                >
                    <img
                        src="/sns-btn/btn-insta.png"
                        alt="insta"
                    />
                </a>
            </div>
        );

        const url = window.location.href
        const twitterShare = (
            <div id="twitter-share">
                <Share
                    url={`${url}
`}
                    options={
                        {
                            text: `「${this.props.contest_info.contest_name}」に参加中の「${this.props.candidate_info.candidate_name}」に投票したよ✨
`,
                            hashtags: `MixChannelYELL,ミクチャ`
                        }
                    }
                />
            </div>
        );

        const candidateProfileInfoHeader = (
            <>
                <MediaQuery query="(min-width:768px)">
                    {entryNumber}
                </MediaQuery>
                <MediaQuery query="(max-width:767px)">
                    <div className="top">
                        {entryNumber}
                        <div className="share-btn">
                            {contestStatus.contest_state === 2 && twitterShare}
                        </div>
                    </div>
                </MediaQuery>
                <div className="pair">
                    {candidateName}
                    <div className="btns-sns">
                        {typeof mixchId !== 'undefined' &&
                            mixchId !== '' &&
                            btnSnsMixch}
                        {typeof twitterId !== 'undefined' &&
                            twitterId !== '' &&
                            btnSnsTwitter}
                        {typeof instagramId !== 'undefined' &&
                            instagramId !== '' &&
                            btnSnsInstagram}
                    </div>
                </div>
                <div className="contest-info">
                    {contestName}
                    {votePeriod}
                </div>
            </>
        );

        const candidateProfileInfoRowsArr = [
            {
                name: "学部学科",
                val: this.props.candidate_info.department
                +
                this.props.candidate_info.grade,
            },
            {
                name: "誕生日",
                val: <Moment format="M月D日">
                    {this.props.candidate_info.birthday}
                </Moment>,
            },
            {
                name: "出身地",
                val: this.props.candidate_info.birthplace,
            },
            {
                name: "身長",
                val: this.props.candidate_info.height + "cm",
            },
            {
                name: "趣味",
                val: this.props.candidate_info.hobby,
            },
            {
                name: "配信日",
                val: this.props.candidate_info.delivery_date,
            },
            {
                name: "配信内容",
                val: this.props.candidate_info.delivery_contents,
            },
        ];

        const candidateProfileInfoRows = candidateProfileInfoRowsArr.map( (item, i) => (
            <div key={i} className="profile-details">
                <span className="name">
                    {item.name || ''}
                </span>
                <span className="val">
                    {item.val || ''}
                </span>
            </div>
        ));

        const candidateVoteButton = (
            <div className="candidate-profile-btn">
                {this.props._user_service.login_status &&
                    <ContestVoteLimit
                        contest_vote_limit={this.props.contest_vote_limit}
                        contest_status={this.props.contest_status}
                    />
                }
                <CandidateVote
                    contest_id={this.props.candidate_info.contest_id}
                    candidate_id={this.props.candidate_info.candidate_id}
                    contest_status={contestStatus}
                    candidate_vote_limit={contestVoteLimit}
                    onCandidateVote={this.handleCandidateVote}
                />
            </div>
        );

        const candidateProfileInfo = (
            <div className="candidate-profile-contents">
                <div className="candidate-images">
                    {candidateProfileInfoImages}
                </div>
                <div className="candidate-profile">
                    {candidateProfileInfoHeader}
                    {candidateProfileInfoRows}
                </div>
            </div>
        );

        const candidateProfileModalHeader = (
            <img
                src={this.state.imageUrl}
                className="candidate-images"
                alt=""
            />
        );

        return (
            <div id="candidate-profile-info">
                <MediaQuery query="(min-width:768px">
                    <div className="share-btn">
                        {contestStatus.contest_state === 2 && twitterShare}
                    </div>
                </MediaQuery>
                {candidateProfileInfo}
                <MediaQuery query="(max-width:767px)">
                    {/* ダウンロード広告付近まで表示 */}
                    {this.state.coordinateY + clientScreenHeight < bodyHeight * 0.8 && candidateVoteButton }
                </MediaQuery>
                <MediaQuery query="(min-width:768px)">
                    {candidateVoteButton}
                </MediaQuery>
                <CommonModal
                    show={modalIsOpen}
                    closeModal={this.closeModal}
                    header={candidateProfileModalHeader}
                    dialogClassName="candidate-profile-image"
                    size="lg"
                />
            </div>
        );
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true});
    }
}

export default CandidateProfileInfo;