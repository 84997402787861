import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import './Footer.scss';

class Footer extends React.Component {

    render() {
        return (
            <>
                <Row
                    id="bnr"
                    xs={1}
                    sm={2}
                >
                    <Col className="app-bnr">
                        <div className="desc">
                            <p>
                                いつでもどこでも「ミクチャ」をチェック！
                            </p>
                            <MediaQuery query="(max-width: 767px)">
                                <div>
                                    ミスコンモデルたちのライブ配信を見に行こう♩
                                </div>
                            </MediaQuery>
                            <MediaQuery query="(min-width: 768px)">
                                <div>
                                    ミスコンモデルたちの<br />ライブ配信を見に行こう♩
                                </div>
                            </MediaQuery>
                        </div>
                        <div className="app-store-image">
                            <MediaQuery query="(max-width: 767px)">
                                <div className="to-app-store">
                                    <a href="https://app.adjust.com/joe87v9">
                                        <button className="download-btn">
                                            <span>ダウンロードはこちら</span>
                                        </button>
                                    </a>
                                </div>
                            </MediaQuery>
                            <MediaQuery query="(min-width: 768px)">
                                <div className="download-bnr">
                                    <span>ダウンロードはこちらから >></span>
                                    <img className="mixcma" src="/mixcma.png" alt="mixcma" />
                                    <img className="mixchyell-qr" src="/mixchyell-qr.png" alt="mixch-qr" />
                                </div>
                            </MediaQuery>
                        </div>
                    </Col>
                    <Col className="image-store">
                        <Image src="/img-store.png" />
                    </Col>
                </Row>
                <div className="border-line"></div>
            </>
        );
    }
}

export default Footer;