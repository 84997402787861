import React from 'react';
import {Modal} from 'react-bootstrap';

class CommonModal extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.props.closeModal();
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.closeModal}
                animation={false}
                size={this.props.size}
                centered="true"
                dialogClassName={this.props.dialogClassName}
            >
                {this.props.header && <Modal.Header><img src="/candidate_modal/btn-close.png" className="close-btn" alt="close-btn" onClick={this.closeModal} />{this.props.header}</Modal.Header>}
                {this.props.body && <Modal.Body>{this.props.body}</Modal.Body>}
                {this.props.footer && <Modal.Footer>{this.props.footer}</Modal.Footer>}
            </Modal>
        );
    }
}

export default CommonModal;