import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies} from 'react-cookie';

import CandidateProfileInfo from './CandidateProfileInfo';
import CandidatesModal from '../common/CandidatesModal';
import Footer from '../common/Footer';
import Header from '../common/Header';
import MediaQuery from 'react-responsive';
import SubFooter from '../common/SubFooter';
import ToOwnerGroupInfoButton from '../common/ToOwnerGroupInfoButton';
import { CandidateProfileBreadcrumbs } from '../common/Breadcrumbs';
import { candidateVote } from '../common/CandidateVoteApi';
import { decodeJsonForYellApi } from '../common/Utils';
import { getCandidateProfile } from './CandidateProfileApi';

import '../common/Base.scss';
import '../common/Breadcrumbs.scss';
import './CandidateProfile.scss';

class CandidateProfile extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };

    constructor(props) {
        super(props);
        const { cookies } = props;
        const _user_service = decodeJsonForYellApi(cookies.get('_user_service'));
        const _oauth_callback_info = decodeJsonForYellApi(cookies.get('_oauth_callback_info'));
        this.handleCandidateVote = candidateVote.bind(this);
        this.handleContestVoteLimitUpdate = this.handleContestVoteLimitUpdate.bind(this);
        this.handleCandidateVoteStatusUpdate = this.handleCandidateVoteStatusUpdate.bind(this);
        this.handleCandidateVoteModalStatusUpdate = this.handleCandidateVoteModalStatusUpdate.bind(this);
        this.handleCandidateProfileModalStatusUpdate = this.handleCandidateProfileModalStatusUpdate.bind(this);
        this.state = {
            candidate_info: [],
            candidate_images: [],
            candidate_interviews: [],
            contest_info: [],
            contest_status: [],
            contest_vote_limit: [],
            candidate_vote_aggregate: [],
            candidate_vote_status: [],
            candidate_vote_modal_is_open: false,
            candidate_profile_modal_is_open: false,
            _user_service: _user_service,
            _oauth_callback_info: _oauth_callback_info,
        };
    }

    handleContestVoteLimitUpdate(data) {
        this.setState({
            contest_vote_limit: data
        });
    }

    handleCandidateVoteStatusUpdate(data) {
        this.setState({
            candidate_vote_status: data
        });
    }

    handleCandidateVoteModalStatusUpdate(isOpen) {
        this.setState({
            candidate_vote_modal_is_open: isOpen,
        });
    }

    handleCandidateProfileModalStatusUpdate(isOpen) {
        this.setState({
            candidate_profile_modal_is_open: isOpen,
        });
    }

    render() {

        const breadcrumbs = (
            <div id="breadcrumbs">
                <div className="breadcrumbs-item">
                    <CandidateProfileBreadcrumbs
                        match={this.props.match}
                        contest_info={this.state.contest_info}
                        candidate_info={this.state.candidate_info}
                    />
                </div>
            </div>
        )

        const interviewTitle = (
            <div className="interview-title">
                <label>{this.state.candidate_info.candidate_name}をもっと詳しく知りたい！</label>
                <h3>INTERVIEW</h3>
            </div>
        );

        const interviewItems = (
            <div className="interview-items">
                {this.state.candidate_interviews.map( (interview, i) => (
                    <div
                        key={interview.question_and_answer_id}
                        className="item"
                    >
                        <p className="question"><span className="question-no">Q{i+1} </span><span className="text">{interview.question}</span></p>
                        <p className="answer">{interview.answer}</p>
                    </div>
                ))}
            </div>
        );

        return (
            <div id="candidate-profile" className="content-area">
                <Header user={this.state._user_service} />
                <MediaQuery query="(min-width:768px)">
                    {breadcrumbs}
                </MediaQuery>
                <CandidateProfileInfo
                    candidate_info={this.state.candidate_info}
                    candidate_images={this.state.candidate_images}
                    contest_vote_limit={this.state.contest_vote_limit}
                    contest_status={this.state.contest_status}
                    contest_info={this.state.contest_info}
                    candidate_vote_aggregate={this.state.candidate_vote_aggregate}
                    handleCandidateProfileModalStatusUpdate={this.handleCandidateProfileModalStatusUpdate}
                    candidate_profile_modal_is_open={this.state.candidate_profile_modal_is_open}
                    onCandidateVote={this.handleCandidateVote}
                    _user_service={this.state._user_service}
                />
                <div className="candidate-interview">
                    {interviewTitle}
                    {interviewItems}
                </div>
                <MediaQuery query="(max-width:767px)">
                    {breadcrumbs}
                    <ToOwnerGroupInfoButton />
                </MediaQuery>
                <Footer />
                <SubFooter _user_service={this.state._user_service} />
                <CandidatesModal
                    contest_vote_limit={this.state.contest_vote_limit}
                    candidate_vote_status={this.state.candidate_vote_status}
                    candidate_vote_modal_is_open={this.state.candidate_vote_modal_is_open}
                    handleCandidateVoteModalStatusUpdate={this.handleCandidateVoteModalStatusUpdate}
                    _oauth_callback_info={this.state._oauth_callback_info}
                    onCandidateVote={this.handleCandidateVote}
                />
            </div>
        )
    }

    componentDidMount() {
        const contestId = this.props.match.params.contest_id;
        const candidateId = this.props.match.params.candidate_id;
        const oauthSuccess = this.state._oauth_callback_info.oauth_success;
        const shouldVoteContestId = this.state._oauth_callback_info.should_vote_contest_id;
        const shouldVoteCandidateId = this.state._oauth_callback_info.should_vote_candidate_id;

        if (oauthSuccess) {
            this.handleCandidateVote(
                shouldVoteContestId,
                shouldVoteCandidateId,
            )
        }

        // 候補者プロフィール取得
        getCandidateProfile(contestId, candidateId)
        .then(
            (responseJson) => {
                this.setState({
                    candidate_info: decodeJsonForYellApi(responseJson[0]),
                    candidate_images: decodeJsonForYellApi(responseJson[1]),
                    candidate_interviews: decodeJsonForYellApi(responseJson[2]),
                    contest_info: decodeJsonForYellApi(responseJson[3]),
                    contest_status: decodeJsonForYellApi(responseJson[4]),
                    contest_vote_limit: decodeJsonForYellApi(responseJson[5]),
                    candidate_vote_aggregate: decodeJsonForYellApi(responseJson[6]),
                });
            },
            (error) => {
                console.log(error);
            }
        );
    }
}

export default withCookies(CandidateProfile);