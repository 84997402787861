export async function getCandidatesData(contestId) {
    const apiVersion = '/api/v1';
    const resourceContest = `${apiVersion}/contests/${contestId}`;
    const resourceContestStatus = `${resourceContest}/status`;
    const resourceContestVoteLimit = `${resourceContest}/vote-limit`;
    const resourceContestVoteAggregate = `${resourceContest}/vote-aggregate`;
    const resourceCandidates = `${resourceContest}/candidates`;
    try {
        const responses = await Promise.all(
            [
                resourceContestStatus,
                resourceCandidates,
                resourceContest,
                resourceContestVoteLimit,
                resourceContestVoteAggregate,
            ].map(url =>  {
                return fetch(url);
            })
        );
        return await Promise.all(responses.map(r => r.json()));
    }
    catch (error) {
        console.log(error);
    }
}