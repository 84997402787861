import { decodeJsonForYellApi } from './Utils';

export async function candidateVote(contestId, candidateId) {
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    const apiVersion = '/api/v1';
    const requestCandidateVote = `${apiVersion}/contests/${contestId}/candidates/${candidateId}/vote`;
    const resourceContestVoteLimit = `${apiVersion}/contests/${contestId}/vote-limit`;
    const lineOauthUrl = `${apiBaseUrl}${apiVersion}/oauth/line`;
    try {
        // 投稿リクエスト
        const response = await fetch(requestCandidateVote, {
                method: 'post',
        });
        const responseJson = await response.json();
        const candidateVoteJson = decodeJsonForYellApi(responseJson);
        const candidateVoteState = candidateVoteJson.candidate_vote_state;
        // 投稿後処理
        if (candidateVoteState === 5) {
            // 未ログイン
            window.location.href = lineOauthUrl;
            return;
        } else if (candidateVoteState === 1) {
            try {
                // 投稿成功時は、投稿上限を更新
                const response = await fetch(resourceContestVoteLimit);
                const responseJson = await response.json();
                const contestVoteLimitJson = decodeJsonForYellApi(responseJson);
                this.handleContestVoteLimitUpdate(contestVoteLimitJson);
            } catch(error) {
                console.log(error);
            }
        }
        // モーダル処理
        this.handleCandidateVoteStatusUpdate(candidateVoteJson);
        this.handleCandidateVoteModalStatusUpdate(true);
    } catch (error) {
        console.log(error);
    }
}
