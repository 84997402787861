import React from 'react';
import { PrivacyPolicyBreadcrumbs } from '../common/Breadcrumbs';
import Header from '../common/Header';
import Footer from '../common/Footer';
import SubFooter from '../common/SubFooter';
import MediaQuery from 'react-responsive';
import './PrivacyPolicy.scss';
import { decodeJsonForYellApi } from '../common/Utils';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies} from 'react-cookie';

class PrivacyPolicy extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };

    constructor(props) {
        super(props);
        const { cookies } = props;
        const _user_service = decodeJsonForYellApi(cookies.get('_user_service'));
        this.state = {
            _user_service: _user_service,
        }
    }

    render() {
        const breadcrumbs = (
            <div id="breadcrumbs">
                <div className="breadcrumbs-item">
                    <PrivacyPolicyBreadcrumbs />
                </div>
            </div>
        );

        return (
            <div id="privacy-policy" className="content-area">
                <Header user='_user_service' />
                <MediaQuery query="(min-width: 768px)">
                    {breadcrumbs}
                </MediaQuery>
                <div className="privacy-policy">
                    <h1>プライバシーポリシー</h1>
                    <h2 className="title"><label className="title-header">　</label>　プライバシーポリシーの適用範囲</h2>
                    <p className="body">
                        このプライバシーポリシーは、株式会社Donuts（以下「当社」といいます。）が本ウェブサイト上で提供するサービス（以下「本サービス」といいます。）について、本サービスを利用する方（以下「ユーザー」といいます。）と当社の関係に適用されます。<br />
                        当社が取得した個人情報は、このプライバシーポリシーにしたがって管理されます。<br />
                        プライバシーポリシーは、当社が直接的に個人情報を取得していない場合には適用されません。<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　個人情報の取得</h2>
                    <p className="body">
                        当社は、適法かつ公正な手段によって、個人情報を取得します。<br />
                        当社は、ユーザーの利便性を向上させる目的で統計データを作成するために、ユーザーが当社のサービスを利用するに当たり、ユーザーのIPアドレス、クッキー情報、ユーザーが閲覧したページその他のアクセスログならびにユーザーの利用環境などの情報を自動的に取得することがあります。<br />
                        当社が取得するユーザーの個人情報は、利用目的の達成に必要な範囲内のものに限られます。<br />
                        当社は、ユーザーのプロフィールの登録をお願いする際や当社にお問い合わせをいただく際に、ユーザーの個人情報をお尋ねすることがあります。<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　個人情報の利用目的</h2>
                    <p className="body">
                        当社は、次に掲げる目的でユーザーの個人情報を利用させていただきます。<br />
                        (1) 本サービスの提供および運営のため<br />
                        (2) ユーザーの属性に合わせた当社のサービスの提供および広告配信を行うため<br />
                        (3) ユーザーに本サービスをご利用頂くための本人確認、本人認証のため<br />
                        (4) ユーザーからのお問い合わせへのご対応のため<br />
                        (5) 本サービスにおけるユーザーの利便性を向上させる目的で利用動向の調査、統計、分析を行い、統計データを作成するため<br />
                        (6) 当社の新サービス、新機能の開発のため<br />
                        (7) システムの維持・改善、不具合対応およびこれらの技術的なサポートに関する情報の告知のため<br />
                        (8) 利用規約に反する態様でのご利用を防止するため<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　個人情報の利用</h2>
                    <p className="body">
                        当社は、個人情報を利用目的の達成に必要な範囲内で取り扱います。ただし、次に掲げる場合はこの限りではありません。<br />
                        (1) 法令に基づく場合<br />
                        (2) 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br />
                        (3) 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br />
                        (4) 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　統計データの利用</h2>
                    <p className="body">
                    当社は、ユーザーの個人情報をもとに、個人を識別できないように加工した統計データを作成することがあります。<br />
                    個人を識別できない統計データについては、個人情報には当たらず、当社は何ら制限なく利用することができるものとします。<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　個人情報の第三者への提供</h2>
                    <p className="body">
                        当社は、以下の場合を除いて、個人情報を、事前に本人の同意を得ることなく、第三者（なお念のため付言すると次条における委託先を含みません。）に販売、貸与、その他の方法により提供することはありません。<br />
                        (1) 法令に基づく場合<br />
                        (2) 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br />
                        (3) 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br />
                        (4) 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　外部委託について</h2>
                    <p className="body">
                        当社は、情報処理などの業務を外部に委託する際に、委託先に対して、個人情報を提供する場合があります。個人情報の提供を行う委託先は、当社に対して秘密保持義務を負担している者に限ります。<br />
                        当社は、個人情報の取扱いの全部又は一部を委託する場合は、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うものとします。<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　個人情報の管理</h2>
                    <p className="body">
                        当社は、利用目的の達成に必要な範囲内において、個人情報を正確かつ細心の内容に保つよう努めるものとします。<br />
                        ユーザーの個人情報はユーザーのプライバシー保護およびセキュリティの為パスワードで保護されています。<br />
                        当社は必要がある場合、データ転送を保護する為に業界標準のSSL暗号を使用します。<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　組織・体制</h2>
                    <p className="body">
                        当社は、個人情報の漏えい、滅失または毀損の防止その他個人情報の安全管理のために、必要かつ適切な措置を講ずるものとします。<br />
                        当社は、その従業者に個人情報を取り扱わせるに当たっては、当該個人情報の安全管理が図られるよう、当該従業者に対する必要かつ適切な監督を行うものとします。<br />
                    </p>

                    <h2 className="title"><label className="title-header">　</label>　個人情報の開示等について</h2>
                    <p className="body">
                        当社は、ユーザーがその個人情報について、開示・訂正・利用停止・消去等を求める場合には、その合理性を遅滞なく調査・確認し、可能な限りこれに対応します。<br />
                        ただし、当社の業務の適正な実施に支障がある場合またはユーザーの個人情報以外の情報開示については、お断りすることがあります。<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　第三者企業のトラッキングについて</h2>
                    <p className="body">
                        当社は、Google を含む第三者配信事業者によりインターネット上のさまざまなサイトに当社の広告を掲載することがあります。<br />
                        Google を含む第三者配信事業者は Cookie を使用して、当ウェブサイトへの過去のアクセス情報に基づいて広告を配信します。<br />
                        ユーザーは Google 広告のオプトアウト ページにアクセスして、Google による Cookie の使用を無効にできます。<br />
                    </p>
                    <h2 className="title"><label className="title-header">　</label>　プライバシーポリシーの改正について</h2>
                    <p className="body">
                        当社はプライバシーポリシーの全部または一部を改定することがあります。<br />
                        重要な変更がある場合には、サイト上でお知らせします。<br />
                    </p>
                    <MediaQuery query="(max-width: 767px)">
                        {breadcrumbs}
                    </MediaQuery>
                </div>
                <Footer />
                <SubFooter _user_service={this.state._user_service} />
            </div>
        );
    }
}

export default withCookies(PrivacyPolicy);