
import React from "react";
import ReactLazyLoad from 'react-lazyload';

function LazyLoad(props) {
    return (
        <ReactLazyLoad
            height="100%"
            offset={[-50, 50]}
            resize
        >
            {props.children}
        </ReactLazyLoad>
    )
}

export default LazyLoad;