import React from 'react';
import './CandidateVote.scss';

class CandidateVote extends React.Component {

    // 投稿処理
    handleCandidateVote(contestId, candidateId) {
        this.props.onCandidateVote(contestId, candidateId)
    }

    handleBtnType(contestId, candidateId, contestStatus, candidateVoteLimit) {
        const voteBtn = (
            <button
                onClick={() => this.handleCandidateVote(
                    contestId,
                    candidateId,
                )}
                className="abled"
            >
                <span>応援する</span>
            </button>
        );

        const disabledBtn = (
            <button
                onClick={() => this.handleCandidateVote(
                    contestId,
                    candidateId,
                )}
                className="disabled"
                disabled="true"
            >
                <span>応援済み</span>
            </button>
        );

        const hideBtn = (
            <button
                className="hide"
                disabled
            >
                <span>応援する</span>
            </button>
        );

        const contestState = this.props.contest_status.contest_state;
        const voteLimitDate = candidateVoteLimit.vote_limit_date;
        const voteCnt = candidateVoteLimit.vote_cnt;

        if (contestState !== 2) {
            return hideBtn;
        }

        if (voteLimitDate !== 0 && voteLimitDate <= voteCnt) {
            return disabledBtn;
        }
        return voteBtn;
    }

    render() {
        const contestId = this.props.contest_id;
        const candidateId = this.props.candidate_id;
        const contestStatus = this.props.contest_status;
        const candidateVoteLimit = this.props.candidate_vote_limit;
        return (
            <div className="vote-btn">
                {this.handleBtnType(
                    contestId,
                    candidateId,
                    contestStatus,
                    candidateVoteLimit,
                )}
            </div>
        );
    }
}

export default CandidateVote;