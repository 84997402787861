import React from 'react';
import ContestVoteLimit from './ContestVoteLimit';
import ContestStatus from './ContestStatus';
import Moment from 'react-moment';
import './SubHeader.scss';
import MediaQuery from 'react-responsive';
import { Share } from 'react-twitter-widgets';

class SubHeader extends React.Component {

    render() {
        const collegeName = (
            <div className="college-name">
                {this.props.contest_info.college_name}
            </div>
        );

        const contestStatus = (
            <ContestStatus data={this.props.contest_status} />
        );

        const contestName = (
            <div className="contest-name">
                {this.props.contest_info.contest_name}
            </div>
        );

        const contestVoteLimit = (
            <>
                {this.props._user_service.login_status &&
                    <ContestVoteLimit 
                        contest_vote_limit={this.props.contest_vote_limit}
                        contest_status={this.props.contest_status}
                    />
                }
            </>
        );

        const url = window.location.href

        const twitterShare = (
            <>
                {this.props.contest_status.contest_state === 2 &&
                    <div id="twitter-share">
                        <Share
                            url={`${url}
`}
                            options={
                                {
                                    text: `「${this.props.contest_info.contest_name}」投票受付中！
コンテスト参加者をチェックしよう👀
`,
                                    hashtags: `MixChannelYELL,ミクチャ`
                                }
                            }
                        >Tweet</Share>
                    </div>
                }
            </>
        );

        const votePeriod = (
            <div className="vote-period">
                投票期間：
                <Moment>
                    {this.props.contest_info.vote_start_time}
                </Moment>
                〜
                <Moment>
                    {this.props.contest_info.vote_end_time}
                </Moment>
            </div>
        );

        return (
            <div id="sub-header">
                <div className="contents">
                    <div className="top">
                        {contestStatus}
                        {collegeName}
                        <MediaQuery query="(min-width: 768px)">
                            {contestVoteLimit}
                        </MediaQuery>
                    </div>
                    <div className="middle">
                        {contestName}
                        <MediaQuery query="(min-width: 768px)">
                            {twitterShare}
                        </MediaQuery>
                    </div>
                    {votePeriod}
                    <MediaQuery query="(max-width: 767px)">
                        {twitterShare}
                        {contestVoteLimit}
                    </MediaQuery>
                </div>
            </div>
        );
    }
}

export default SubHeader;