import React from 'react';
import MediaQuery from 'react-responsive';
import { Button, Image } from 'react-bootstrap';
import CommonModal from '../common/Modal';
import './CandidatesModal.scss';

class CandidatesModal extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.props.handleCandidateVoteModalStatusUpdate(false);
    }

    handleCandidateVote(contestId, candidateId) {
        this.props.onCandidateVote(contestId, candidateId);
    }

    render() {
        const candidateVoteState = this.props.candidate_vote_status.candidate_vote_state;
        const candidateVoteStateSuccess       = 1; // 投票成功
        const candidateVoteStateFailed        = 2; // 投票失敗
        const candidateVoteStateVoteLimitOver = 3; // 投票上限超過
        const candidateVoteStateLoginFailed   = 4; // ログイン失敗
        const candidateVoteStateEndPeriod     = 6; // 投票期間終了

        const modalIsOpen = this.props.candidate_vote_modal_is_open;
        const voteLimitDate = this.props.contest_vote_limit.vote_limit_date;
        const shouldVoteContestId = this.props._oauth_callback_info.should_vote_contest_id;
        const shouldVoteCandidateId = this.props._oauth_callback_info.should_vote_candidate_id;

        const candidateVoteStateSuccessModalHeader = (
            <h2>応援ありがとうございました！</h2>
        );
        const CandidateVoteStateSuccessModalFooter = (
            <div className="app-store-image" sm={2} xs={1}>
                <MediaQuery query="(max-width:767px)">
                    <div className="to-app-store">
                        <a href="https://app.adjust.com/joe87v9">
                            <button className="download-btn">
                                <span>インストール</span>
                            </button>
                        </a>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width:768px)">
                    <div className="app-bnr">
                        <div className="desc">
                            <div>
                                ミスコンモデルたちの<br />ライブ配信を見に行こう♩
                            </div>
                        </div>
                            <MediaQuery query="(min-width: 768px)">
                                <div className="download-bnr">
                                    <span>ダウンロードはこちらから ></span>
                                    <Image className="mixchyell-qr" src="/mixchyell-qr.png" />
                                </div>
                            </MediaQuery>
                    </div>
                </MediaQuery>
            </div>
        );
        const candidateVoteStateSuccessModalBody = (
            <div className="success-modal-body">
                {candidateVoteStateSuccessModalHeader}
                <Image src='/candidate_modal/mixcma.png'
                    className="image"
                    alt=""
                />
                <MediaQuery query="(max-width:767px)">
                    <p>ライブ配信を見に行こう！</p>
                </MediaQuery>
                {CandidateVoteStateSuccessModalFooter}
            </div>
        );

        const CandidateVoteStateFailedModalFooter = (
            <Button className="ok-btn" onClick={this.closeModal}>
                <span>OK</span>
            </Button>
        );
        const CandidateVoteStateFailedModalBody = (
            <div className="faild-modal-body">
                <h3>投票に失敗しました</h3>
                {CandidateVoteStateFailedModalFooter}
            </div>
        );

        const candidateVoteStateVoteLimitOverFooter = (
            <Button className="ok-btn" onClick={this.closeModal}>
                <span>OK</span>
            </Button>
        );
        const candidateVoteStateVoteLimitOverBody = (
            <div className="vote-limit-over-modal-body">
                <h3>
                    {`投票は1日${voteLimitDate}回となっております`}
                </h3>
                <p>
                    {`（投票権は毎日0:00に復活します)`}
                </p>
                {candidateVoteStateVoteLimitOverFooter}
            </div>
        );

        const candidateVoteStateLoginFailedFooter = (
            <>
                <Button className="ok-btn type-double" onClick={this.closeModal}>
                    <span>閉じる</span>
                </Button>
                <Button className="login-btn" variant="secondary" onClick={() => {
                    this.handleCandidateVote(
                        shouldVoteContestId,
                        shouldVoteCandidateId,
                    )
                }}>
                    <span>再ログイン</span>
                </Button>
            </>
        );
        const candidateVoteStateLoginFailedBody = (
            <div className="login-failed-modal-body">
                <h3>ログインに失敗したため投稿できませんでした</h3>
                <div className="btns">
                    {candidateVoteStateLoginFailedFooter}
                </div>
            </div>
        );

        const candidateVoteStateEndPeriodFooter = (
            <Button className="ok-btn" onClick={this.closeModal}>
                <span>OK</span>
            </Button>
        );
        const candidateVoteStateEndPeriodBody = (
            <div className="end-period-modal-body">
                <h3>投票期間は終了しました</h3>
                {candidateVoteStateEndPeriodFooter}
            </div>
        );

        if (candidateVoteState === candidateVoteStateSuccess) {
            return (
                <CommonModal
                    show={modalIsOpen}
                    closeModal={this.closeModal}
                    header={candidateVoteStateSuccessModalBody}
                    dialogClassName="candidate-modal-success"
                    size="md"
                />
            );
        } else if (candidateVoteState === candidateVoteStateFailed) {
            return (
                <CommonModal
                    show={modalIsOpen}
                    closeModal={this.closeModal}
                    header={CandidateVoteStateFailedModalBody}
                    dialogClassName="candidate-modal-failed"
                    size="md"
                />
            );
        } else if (candidateVoteState === candidateVoteStateVoteLimitOver) {
            return (
                <CommonModal
                    show={modalIsOpen}
                    closeModal={this.closeModal}
                    header={candidateVoteStateVoteLimitOverBody}
                    dialogClassName="candidate-modal-failed"
                    size="md"
                />
            );
        } else if (candidateVoteState === candidateVoteStateLoginFailed) {
            return (
                <CommonModal
                    show={modalIsOpen}
                    closeModal={this.closeModal}
                    header={candidateVoteStateLoginFailedBody}
                    dialogClassName="candidate-modal-failed"
                    size="md"
                />
            );
        } else if (candidateVoteState === candidateVoteStateEndPeriod) {
            return (
                <CommonModal
                    show={modalIsOpen}
                    closeModal={this.closeModal}
                    header={candidateVoteStateEndPeriodBody}
                    dialogClassName="candidate-modal-failed"
                    size="md"
                />
            );
        }
        return <></>;
    }
}

export default CandidatesModal;