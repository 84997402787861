import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import 'moment-timezone';
import {CookiesProvider} from 'react-cookie';
import Contests from './components/contests/Contests';
import Candidates from './components/candidates/Candidates';
import CandidateProfile from './components/candidate_profile/CandidateProfile';
import OwnerGroupInfo from './components/contact_us/OwnerGroupInfo';
import PrivacyPolicy from './components/contact_us/PrivacyPolicy';
import {ThroughProvider} from 'react-through';
import ScrollToTop from './components/common/ScrollToTop';

// // Sets the moment instance to use.
Moment.globalMoment = moment;
 
// // Set the locale for every react-moment instance to French.
Moment.globalLocale = 'ja';
 
// // Set the output format for every react-moment instance.
Moment.globalFormat = 'L(dd)';
// Moment.globalFormat = 'YYYY年MMMDo(dd)';
 
// // Set the output timezone for local for every instance.
Moment.globalLocal = true;

Moment.startPooledTimer(60000);

ReactDOM.render(
  // React.StrictModeを設定すると2回レンダリングが実行される。
  // 本番モードでは1回のレンダリングとなる。
  <CookiesProvider>
    <ThroughProvider>
      <BrowserRouter>
      {/* <React.StrictMode> */}
        <ScrollToTop />
        <Route exact path="/" component={Contests} />
        <Route exact path="/contests/:contest_id/candidates" component={Candidates} />
        <Route path="/contests/:contest_id/candidates/:candidate_id" component={CandidateProfile} />
        <Route path="/owner-group-info" component={OwnerGroupInfo} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
      {/* </React.StrictMode> */}
      </BrowserRouter>
    </ThroughProvider>
  </CookiesProvider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
